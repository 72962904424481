













import { Component, Vue } from 'vue-property-decorator'
import camelCaseKeys from 'camelcase-keys'
import axios from 'axios'

import EventsForm from '@/partials/forms/Events/EventsForm.vue'
import SubscriptionTypesTable from '@/partials/tables/Events/Subscriptions/Types/index.vue'

@Component({
  components: {
    EventsForm,
    SubscriptionTypesTable
  }
})
export default class EventEdit extends Vue {
  done = false
  event = {}
  created () {
    this.done = false
    axios.get(`/event/${this.$route.params.id}`)
      .then(response => {
        this.event = camelCaseKeys(response.data.data, { deep: true })
        this.done = true
      })
  }
}
